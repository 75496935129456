import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RoleType } from 'src/app/shared/enums/user/role-types.enum';
import { IEventPostData } from 'src/app/shared/models/event/event-post-data.model';
import { IEvent } from 'src/app/shared/models/event/event.model';
import { IUserRegistrationPostData } from 'src/app/shared/models/user/user-registration-post-data.model';
import { IOrgUser, IUser, IUserRole } from 'src/app/shared/models/user/user.model';
import { CallableNames, DbService } from '../../db.service';
import { ITicket } from 'src/app/shared/models/tickets/ticket.model';
import { Language } from 'src/app/shared/enums/utils/languages.enum';
import { ActionBy } from 'src/app/shared/enums/utils/action-by.enum';
import { ILocket } from 'src/app/shared/models/locket/locket.model';
import { IOrganizationCustomMemberFormSettings } from 'src/app/shared/models/organization/organization.model';
import { IOrgUsersFilterData } from '../../store/org-users-store.service';
import { GetCustomDataResultType } from './customer/customers.service';

export enum AdminPostRegistrationWithEventResultType {
  SIGN_IN = 'SIGN_IN', // user with email already exists - sign in and FE should resend this request
  NEW_USER_CREATED = 'NEW_USER_CREATED', // new user, org, userRole, event; email with password sent
};
export interface IAdminPostRegistrationWithEventResult {
  resultType: AdminPostRegistrationWithEventResultType;
  signInToken?: string;
  event?: IEvent;
};

export interface ICallablesUserDataGet {
  userId: number;
  organizationId: number;
}

export interface IUserWhisperData {
  query: string;
  onlyEmail?: boolean;

  organizationIdCustomer?: number;
}

export interface IGetUsersForSendingTicketData {
  eventId: number | null;
  shopItemId: number | null;
}

interface IGetUsersCheckinStats {
  eventId: number | null;
  shopItemId: number | null;
}

export interface IUserUpdateAccountData {
  organizationId: number;
  name: string;
  surname: string;
  preferredLanguage: Language;
  profilePictureBase64: string | undefined;
}

export interface ICallablesUsersChangeEmailData {
  oldEmail: string;
  newEmail: string;
  resendMail?: boolean;
}

interface ICallablesOrganizationMembersGetOrgMemberDataData {
  userId: number | null; 
  orgId: number;
}
export type OrgMemberCustomDataResultType = IOrganizationCustomMemberFormSettings & {
  value: any | null;
  collectedAt: string | null;
};

interface ICallablesUsersLecturerGetSessionsData {
  page: number;
  size: number;
  orgId: number;
  userId: number;
}

interface IGetUsersByData {
  uuid?: string; // načtení uživ. dat po přihlášení
  magicCodeUuid?: string;
  id?: number; // načtení uživ. dat podle id
  organizationId?: number; // načtení všech už. dat v organizaci 
  roles?: RoleType[];

  filters?: IOrgUsersFilterData;

  actionBy: ActionBy;
}

export enum SessionState {
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  PLANNED = 'PLANNED',
  MISSING_LECTURER_ATTENDANCE = 'MISSING_LECTURER_ATTENDANCE'
}

export interface IOrgLecturerSession {
  uuid: string;
  state: SessionState;
  startAt: Date;
  endAt: Date;
  shopItemTitle: string;
  shopItemUuid: string;
  eventUuid: string;
  title: string | null;
  index: number;
  differentEndDay: boolean;
}

interface ICallablesUserDataGetLecturerTopicsData {
  orgId: number;
  orgMemberDataUuid: string;
}

export interface ITitles {
  titlesBefore: string | null;
  titlesAfter: string | null;
}

interface IGetUserDataAdditionalData {
  titles: ITitles;
}

interface IGetUserDataResult {
  userData: GetCustomDataResultType[];
  additionalData: IGetUserDataAdditionalData;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private dbService: DbService,
  ) { }

  public getOrgMemberData(data: ICallablesOrganizationMembersGetOrgMemberDataData): Observable<OrgMemberCustomDataResultType[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrganizationMembersGetOrgMemberData, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getOrgLecturerSessions(data: ICallablesUsersLecturerGetSessionsData): Observable<{ data: IOrgLecturerSession[]; next: boolean; }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersLecturerGetSessions, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateAccount(data: IUserUpdateAccountData): Observable<IUser> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersUpdateAccount, data });
    return this.dbService.handleObs(obs);
  }

  public getCheckinStats(data: IGetUsersCheckinStats): Observable<{checkedCount: number, allCount: number}> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGetCheckinStats, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getUserForSendingTicket(data: IGetUsersForSendingTicketData): Observable<(IUser & { tickets: ITicket[] })[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGetForSendingTicket, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public whisper(data: IUserWhisperData): Observable<IUser[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersWhisper, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getUserByUid(uuid: string, actionBy: ActionBy): Observable<IUser & { roles: IUserRole[], lockets: ILocket[] } | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGet, data: { uuid, actionBy } });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public getUserForStore(): Observable<IUser & { orgUsers: IOrgUser[]; roles: IUserRole[]; lockets: ILocket[]; }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGetForStoreFE });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getUserByMagicCode(uuid: string, actionBy: ActionBy): Observable<IUser | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGet, data: { magicCodeUuid: uuid, actionBy } });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public getUsersByOrganizationId(data: IGetUsersByData): Observable<(IUser & { roles: IUserRole[], lockets: ILocket[] })[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGet, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getUserById(id: number, actionBy: ActionBy, organizationId?: number): Observable<IUser & { roles: IUserRole[], lockets: ILocket[] } | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGet, data: { id, actionBy, organizationId } });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public postRegistration(data: IUserRegistrationPostData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersPostRegistration, data });
    return this.dbService.handleObs(obs);
  }

  public postRegistrationWithEvent(data: IEventPostData): Observable<IAdminPostRegistrationWithEventResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersAdminPostRegistrationWithEvent, data });
    return this.dbService.handleObs(obs);
  }

  public doesRegisteredUserExistByEmail(email: string) {
    email = email.trim().toLowerCase();
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersGetExistRegistered, data: { email } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public changeUserEmail(data: ICallablesUsersChangeEmailData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersChangeEmail, data });
    return this.dbService.handleObs(obs);
  }

  public isRoleOfOrg(userRoles: IUserRole[], role: RoleType, orgId: number | null | undefined) {
    return !!userRoles.find(x => x.type === role && x.organizationId === orgId);
  }
  public hasAnyRolesOfOrg(userRoles: IUserRole[], roles: RoleType[], orgId: number | null | undefined) {
    for (let role of roles) {
      if (this.isRoleOfOrg(userRoles, role, orgId)) return true;
    }
    return false;
  }

  public getUserData(data: ICallablesUserDataGet): Observable<IGetUserDataResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUserDataGet, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getLecturerTopics(data: ICallablesUserDataGetLecturerTopicsData): Observable<string[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUserDataGetLecturerTopics, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
