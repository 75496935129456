import { Injectable } from '@angular/core';
import { IOrgUser, IOrgUserObj } from '../../models/user/user.model';
import { UtilsService } from '../utils.service';
import { ErrorSeverity } from '../error.service';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {

  constructor(
    private utilsService: UtilsService,
  ) { }


  // not using 'CURRENT' in organizationId param bcs it introduces circular dependency
  public getOrgUserObj(orgUsers: IOrgUser[], organizationId: number | null): IOrgUserObj {
    const orgId = organizationId;
    const orgUser = orgUsers.find((o) => o.organizationId === orgId);
    // imo orgUser should be always found
    return {
      email: orgUser?.email ?? null,
      name: orgUser?.name || '',
      surname: orgUser?.surname || '',
      titlesBefore: orgUser?.titlesBefore ?? null,
      titlesAfter: orgUser?.titlesAfter ?? null,
    };
  }

  // not using 'CURRENT' in organizationId param bcs it introduces circular dependency
  public getOrgUserName(obj: IOrgUser[], orgId: number | null, includeTitles: boolean): string;
  public getOrgUserName(obj: IOrgUser | IOrgUserObj, includeTitles: boolean): string;
  public getOrgUserName(obj: IOrgUser | IOrgUserObj | IOrgUser[], orgIdOrIncludeTitles?: number | boolean | null, maybeIncludeTitles?: boolean): string {
    let user: IOrgUser | IOrgUserObj | undefined | null;
    let includeTitles: boolean;

    if (Array.isArray(obj)) {
      const orgId = typeof orgIdOrIncludeTitles === 'number' ? orgIdOrIncludeTitles : null;
      includeTitles = maybeIncludeTitles as boolean;

      if (orgId === null) {
        this.utilsService.logError('orgId missing in userNameOrEmail Pipe, qw8ytruie', ErrorSeverity.ERROR);
        return '';
      }

      user = this.getOrgUser(obj, orgId);
    } else {
      user = obj;
      includeTitles = orgIdOrIncludeTitles as boolean;
    }

    let output = ``;
    if (includeTitles && user && user.titlesBefore?.length) output += `${user.titlesBefore}`;
    if (includeTitles && user?.titlesBefore?.length && user.name?.length) output += ' ';
    if (user && user.name?.length) output += `${user.name}`;
  
    return output.trim();
  }

  // not using 'CURRENT' in organizationId param bcs it introduces circular dependency
  public getOrgUserSurname(obj: IOrgUser[], orgId: number | null, includeTitles: boolean): string;
  public getOrgUserSurname(obj: IOrgUser | IOrgUserObj, includeTitles: boolean): string;
  public getOrgUserSurname(obj: IOrgUser | IOrgUserObj | IOrgUser[], orgIdOrIncludeTitles?: number | boolean | null, maybeIncludeTitles?: boolean): string {
    let user: IOrgUser | IOrgUserObj | undefined | null;
    let includeTitles: boolean;

    if (Array.isArray(obj)) {
      const orgId = typeof orgIdOrIncludeTitles === 'number' ? orgIdOrIncludeTitles : null;
      includeTitles = maybeIncludeTitles as boolean;

      if (orgId === null) {
        this.utilsService.logError('orgId missing in userNameOrEmail Pipe, qw8ytruie', ErrorSeverity.ERROR);
        return '';
      }

      user = this.getOrgUser(obj, orgId);
    } else {
      user = obj;
      includeTitles = orgIdOrIncludeTitles as boolean;
    }

    let output = `${user?.surname ?? ''}`;
    if (output.length && includeTitles && user && user.titlesAfter?.length) output += ', ';
    if (includeTitles && user && user.titlesAfter?.length) output += `${user.titlesAfter}`;
  
    return output.trim();
  }

  // not using 'CURRENT' in organizationId param bcs it introduces circular dependency
  public getOrgUserFullname(obj: IOrgUser[], orgId: number | null, includeTitles: boolean): string;
  public getOrgUserFullname(obj: Partial<IOrgUser | IOrgUserObj>, includeTitles: boolean): string;
  public getOrgUserFullname(obj: Partial<IOrgUser | IOrgUserObj> | IOrgUser[], orgIdOrIncludeTitles?: number | boolean | null, maybeIncludeTitles?: boolean): string {
    let user: Partial<IOrgUser | IOrgUserObj> | undefined | null;
    let includeTitles: boolean;

    if (Array.isArray(obj)) {
      const orgId = typeof orgIdOrIncludeTitles === 'number' ? orgIdOrIncludeTitles : null;
      includeTitles = maybeIncludeTitles as boolean;

      if (orgId === null) {
        this.utilsService.logError('orgId missing in userNameOrEmail Pipe, qw8ytruie', ErrorSeverity.ERROR);
        return '';
      }

      user = this.getOrgUser(obj, orgId);
    } else {
      user = obj;
      includeTitles = orgIdOrIncludeTitles as boolean;
    }
    
    let titlesBefore = user?.titlesBefore || '';
    let titlesAfter = user?.titlesAfter || '';
  
    let output = '';
  
    if (includeTitles && titlesBefore.length) output += `${titlesBefore}`;
    if (output.length) output += ' ';
    if (user?.name) output += `${user.name}`;
    if (output.length) output += ' ';
    if (user?.surname) output += `${user.surname}`;
    if (includeTitles && titlesAfter.length) output += `, ${titlesAfter}`;
  
    return output.trim();
  }

  public getOrgUser(orgUsers: IOrgUser[], organizationId: number | null) {
    if (!organizationId) {
      this.utilsService.logError('No organizationId found! qwfqwfwegweweweqwfqw', ErrorSeverity.ERROR);
      return null;
    }
    return orgUsers.find((orgUser) => orgUser.organizationId === organizationId);
  }

  public getRFC822Email(user: Partial<IOrgUser> | null) {
    if (user === null || !user.email) {
      return '';
    }
    return `${user.name} ${user.surname} <${user.email}>`;
  }


}
