import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { Subscription } from 'rxjs';
import { InflectionPipe } from '../../pipes/inflection.pipe';

@Component({
  selector: 'app-date-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule,
    TranslateModule, InputTextModule, InputMaskModule,
  ],
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit, OnDestroy {
  @ViewChild('dateInput') dateEl: InputMask | null = null;

  @Input() FC: FormControl | null = null;
  @Input() placeholder: string | null = null;
  @Input() label: string | null = null;
  @Input() floatingLabel: string | null = null;
  @Input() justInput: boolean = false;

  oldValue: string | null = null;
  tmpValue: string | null = null;

  subs: Subscription[] = [];

  keyDown = false;

  ngOnInit(): void {
    this.subs.push(this.FC?.valueChanges.subscribe((value) => {
      if (!this.keyDown) {
        // if there's an initial date value in the FC, parse it to a `dd.mm.yyyy` format
        const date = this.FC?.getRawValue();
        if (date) {
          const dateObj = new Date(date);
          if (dateObj?.getTime()) {
            this.FC?.setValue(`${dateObj.getDate().toString().padStart(2, '0')}.${(dateObj.getMonth() + 1).toString().padStart(2, '0')}.${dateObj.getFullYear()}`, { emitEvent: false });
          }
        }
      }

      this.oldValue = this.tmpValue;
      this.tmpValue = value;
    }) || Subscription.EMPTY);

    this.FC?.addValidators(dateValidator.bind(this));
  }

  onDateKeyDown(event: KeyboardEvent) {
    this.keyDown = true;
    const dateInput: string | null | undefined = this.FC?.value;
    const inputEl = this.dateEl!.inputViewChild!.nativeElement as HTMLInputElement;
    if (dateInput) {
      switch (event.key) {
        case '.':
          if (dateInput.includes('d') && !dateInput.includes('dd') && dateInput[0] !== '0') {
            this.FC?.setValue(dateInput.replace(`${dateInput[0]}d`, `0${dateInput[0]}`));
            inputEl.setSelectionRange(3, 3);
          }
          if (dateInput.includes('m') && !dateInput.includes('mm') && dateInput[3] !== '0') {
            this.FC?.setValue(dateInput.replace(`${dateInput[3]}m`, `0${dateInput[3]}`));
            inputEl.setSelectionRange(6, 6);
          }
          break;
        case 'Backspace':
          if (dateInput.includes('0d')) {
            this.FC?.setValue(dateInput.replace(`0d`, `${this.oldValue![1]}d`));
            inputEl.setSelectionRange(1, 1);
          }
          if (dateInput.includes('0m')) {
            this.FC?.setValue(dateInput.replace(`0m`, `${this.oldValue![4]}m`));
            inputEl.setSelectionRange(4, 4);
          }
          break;
        default:
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}



export function minAgeValidator(minAge: number, control: AbstractControl) {
  const value = control.value as string | null;
  if (!value) return null;
  if (dateStringValueValidator(value) !== null) return null;

  const [dayString, monthString, yearString] = value.split('.');
  const [day, month, year] = [+dayString, +monthString, +yearString];

  const dob = new Date(`${year}/${month}/${day}`);
  const currentDate  = new Date();
  const cutOffDate = new Date(currentDate);
  cutOffDate.setFullYear(currentDate.getFullYear() - minAge);

  if (dob > cutOffDate) {
    return { minAge: `${minAge}` }
  }

  return null;
}

export function maxAgeValidator(maxAge: number, control: AbstractControl) {
  const value = control.value as string | null;
  if (!value) return null;
  if (dateStringValueValidator(value) !== null) return null;

  const [dayString, monthString, yearString] = value.split('.');
  const [day, month, year] = [+dayString, +monthString, +yearString];

  const dob = new Date(`${year}/${month}/${day}`);
  
  const currentDate = new Date();
  const cutOffDate = new Date(currentDate);
  cutOffDate.setFullYear(currentDate.getFullYear() - maxAge);

  if (dob <= cutOffDate) {
    return { maxAge: `${maxAge}` };
  }

  return null;
}

export function dateValidator(control: AbstractControl) {

  const value = control.value as string | null;
  return dateStringValueValidator(value);
}

export function dateStringValueValidator(value: string | null) {
  if (!value) {
    return null;
  }

  const [dayString, monthString, yearString] = value.split('.');
  const [day, month, year] = [+dayString, +monthString, +yearString];
  if (!day || !month || !year) {
    return { invalid: true };
  }

  const now = new Date();

  if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1) {
    return { invalid: true };
  }

  const leapYear = (year % 4 === 0) && ((year % 400 === 0) || !(year % 100 === 0));
  const longMonths = [1, 3, 5, 7, 8, 10, 12];
  const shortMonths = [4, 6, 9, 11];
  const maxDays = longMonths.includes(month) ? 31 : shortMonths.includes(month) ? 30 : leapYear ? 29 : 28;

  if (day > maxDays) {
    return { invalid: true };
  }

  const newDate = new Date(`${year}/${month}/${day}`);

  // allow future
  // if (newDate.getTime() > now.getTime()) {
  //   return { invalid: true };
  // }

  return null;
}

export function dateStringToDate(value: string | null) {
  if (!value) {
    return null;
  }

  const [dayString, monthString, yearString] = value.split('.');
  const [day, month, year] = [+dayString, +monthString, +yearString];

  // TODO: different time zones?
  const newDate = new Date(`${year}/${month}/${day}`).addSeconds(7200);
  return newDate;
}

export function dateToDateString(date: Date | null) {
  if (!date) {
    return null;
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${`${day}`.padStart(2, '0')}.${`${month}`.padStart(2, '0')}.${year}`;
}

export function daysString(days: number | null | undefined) {
  const inflectionPipe = new InflectionPipe();
  return inflectionPipe.transform(days, 'utils.days-string');
}
