import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';

export interface ITitlesFG {
  titlesBefore: FormControl<string>;
  titlesAfter: FormControl<string>;
}

@Component({
  selector: 'app-titles-input',
  standalone: true,
  imports: [
    ReactiveFormsModule, InputTextModule, TranslateModule
  ],
  templateUrl: './titles-input.component.html',
  styleUrl: './titles-input.component.scss'
})
export class TitlesInputComponent {
  @Input() titlesFG!: FormGroup<ITitlesFG>;
}

export function getTitlesInputFG() {
  return new FormGroup<ITitlesFG>({
    titlesAfter: new FormControl<string>('', { nonNullable: true }),
    titlesBefore: new FormControl<string>('', { nonNullable: true })
  });
}